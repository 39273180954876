.umferd-cont {
    width: 85%;
    margin: auto;
    max-width: 1200px;
    margin-bottom: 150px;
}
.umferd-cont h2 {
    color: #160259;
    font-family: 'Neue Plak Bold';
    font-size: 15pt;
}

.Opta {
    font-family: 'Neue Plak SemiBold';
}

.Opta h2 {
    display: none;
}

.Opta>.Opta_W {
    border-radius: 20px;
    box-shadow: 0px 3px 8px #454545;
}

.Opta .Opta-MatchHeader .Opta-Score .Opta-Team-Score {
    background-color: transparent;
    border-radius: 10px;
    color: #160259;
    float: right;
    font-size: 25px;
    font-weight: bold;
    min-width: 32px;
    padding: 6px;
    text-align: center;
}

.Opta .Opta-MatchHeader .Opta-Score.Opta-Away .Opta-Team-Score {
    float: left;
    background-color: transparent;
}

.Opta .Opta-MatchHeader tr.Opta-MatchHeader-Details td>div, .Opta .Opta-MatchHeader div.Opta-MatchHeader-Details>div {
    display: inline-block;
    padding: 0 10px;
    min-height: 30px;
    line-height: 30px;
    background-color: #ffffff;
}



.opta-widget-display {
    width: 500px;
}

.statsDropDownItem {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
    position: relative;
}

.absoluteClose {
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 0;
    cursor: pointer;
}

.umferd-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 1px solid #E5E5E5;
    font-family: 'Neue Plak SemiBold';
    font-size: 12pt;
    color: #160259;
    margin-bottom: 50px;
    border-radius: 5px;
}

.umferd-links {
    display: flex;
    color: #1EB6FF;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 4px;
}

.umferd-game {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 100%;
    transition: 0.5s;
}

.umferd-game-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.umferd-game-details {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.umferd-game-dropdown {
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    padding-bottom: 0px;
    transition: 0.7s;
    width: 100%;
}

.umferd-stats-selectors {
    display: flex;
    gap: 10px;
    color: white;
}

.w-400 {
    width: 400px;
}
.w-500 {
    width: 500px;
}

.hidden {
    visibility: hidden;
    height: 0;
}

.umferd-game-statImg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-height: 400px;
    max-width: 700px;
    /*transition: 0.7s;*/
    border-radius: 10px;
}

.umferd-lid {
    display: flex;
    align-items: center;
    width: 18%;
    font-family: 'Neue Plak Bold';
    font-size: 12pt;
}

.umferd-lid img {
    width: 30px;
    height: 30px;
}

.umferd-date {
    width: 33.3%;
    font-size: 12pt;
}

.umferd-location {
    width: 33.3%;
    display: flex;
    justify-content: flex-end;
    font-size: 12pt;
}

.lid-left {
    display: flex;
    justify-content: flex-end;
    margin-right: 15px;
    width: 100%;
}

.lid-left p {
    margin-right: 10px;
}

.lid-right {
    margin-left: 15px;
    width: 100%;
}

.lid-right p {
    margin-left: 10px;
}

.umferd-time {
    background-color: #160259;
    color: white;
    padding-top: 1px;
    padding-bottom: 0px;
    padding-left: 7px;
    padding-right: 6px;
    border-radius: 5px;
    text-align: center;
    font-family: 'Neue Plak Bold';
    font-size: 11pt;
    width: 100px;
}

.teamsTime {
    display: flex;
    width: 100%;
    justify-content: center;
}


@media only screen and (max-width: 1100px) {
    .umferd-game {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-left: 15px;
        margin-right: 15px;
    }

    .umferd-game-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .umferd-game-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .umferd-date {
        margin-bottom: 0;
        margin-top: 6px;
        font-size: 11pt;
        text-align: center;
        width: 50%;
    }

    .umferd-location {
        display: none;
    }

    .teamsTime {
        font-size: 10pt;
    }

    .umferd-lid {
        font-family: 'Neue Plak Bold';
        font-size: 11pt;
    }

    .umferd-lid img {
        width: 25px;
        height: 25px;
    }

    .umferd-time {
        height: 23px;
        padding-top: 1px;
        padding-bottom: 3px;
        padding-left: 6px;
        padding-right: 6px;
    }
}

@media only screen and (max-width: 900px) {

    .umferd-cont {
        width: 90%;
    }
}

@media only screen and (max-width: 600px) {

    .opta-widget-display {
        width: 90%;
    }
}





@font-face {
    font-family: 'Neue Plak Condensed';
    src: url('../../fonts/NeuePlak-CondRegular.woff') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}

@font-face {
    font-family: 'Neue Plak Condensed SemiBold';
    src: url('../../fonts/NeuePlak-CondSemiBold.woff') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}

@font-face {
    font-family: 'Neue Plak SemiBold';
    src: url('../../fonts/Neue-Plak-SemiBold.otf') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}

@font-face {
    font-family: 'Neue Plak Bold';
    src: url('../../fonts/Neue-Plak-Bold.otf') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}