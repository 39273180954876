.countdown-container {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

#countdown-days {
    padding-right: 20px;
}

.Clock-days, .Clock-hours, .Clock-minutes, .Clock-seconds {
    font-size: 50px;
    font-weight: bold;
    color: #1EB6FF;
}

.countdown-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.countdown-item p {
    font-weight: bold;
    color: #160259;
}