.sponsor-banner-container {
    display: flex;
    align-items: center;
    width: 85%;
    max-width: 1200px;
    margin: auto;
    margin-top: 25px;
    border-radius: 5px;
}

.sponsor-banner-container img {
    width: 100%;
}

.sponsor-banner-container-mobile img {
    width: 100%;
}

.sponsor-banner-container-mobile {
    display: none;
    align-items: center;
    width: 100%;
    margin: auto;
    margin-bottom: 50px;
    border-radius: 5px;
}

@media only screen and (max-width: 900px) {
    .sponsor-banner-container {
        display: none;
    }
    .sponsor-banner-container-mobile {
        display: flex;
    }
}