.lid-imgText-tol {
    display: flex;
    align-items: center;
    width: 74%;
}

.played-tol {
    display: flex;
    justify-content: center;
    width: 7%;
}

.lid-imgText-spjold {
    display: flex;
    align-items: center;
    width: 74%;
}

.played-spjold {
    display: flex;
    justify-content: center;
    width: 18%;
}

@media only screen and (max-width: 1100px) {
    .played-tol {
        width: 8%;
    }

    .lid-cont .lid-imgText-tol {
        width: 66%;
    }

    .ldi-imgText-tol {
        width: 74%;
    }

    .lid-imgText-spjold {
        width: 82%;
    }
}

@media only screen and (max-width: 900px) {
    .lid-cont .lid-imgText-tol {
        width: 63%;
    }
}