.footer-container {
    z-index: 1000;
    height: 100%;
    background-color: white;
    padding-top: 50px;
}

.sponsor-cont {
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 50px;
}

.sponsors-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
    margin-right: -1;
}

.sponsor img {
    width: 80%;
    max-width: 300px;
    min-width: 200px;
}

.sponsor {
    flex: 1;
    padding: 0.5em;/* add some padding ?*/
    text-align: center;
    /*border-right: 1px solid #E5E5E5;*/
}

.sponsor:last-child {
    /*border: none; */
}

.footer-items-container {
    display: flex;
    flex-direction: column;
    background-color: white;
}

.info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /*justify-content: space-around;*/
    width: 100%;
    margin-left: 100px;
}

.info-left, .info-middle, .info-right {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Neue Plak Condensed';
    font-size: 14pt;
    letter-spacing: 1px;
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 25px;
}

.bottom-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 100px;
    margin-left: 100px;
    height: 100px;
    border-top: 1px solid #E5E5E5;
}

.bottom-footer p {
    color: #444444;
    font-size: 12pt;
    letter-spacing: 1px;
    font-family: 'Neue Plak Condensed';
}

.info-logo img {
    width: 200px;
}

@font-face {
    font-family: 'Neue Plak Condensed';
    src: url('../../fonts/NeuePlak-CondRegular.woff') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}

@font-face {
    font-family: 'Neue Plak Condensed SemiBold';
    src: url('../../fonts/NeuePlak-CondSemiBold.woff') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}

@media screen and (max-width: 1100px) { 
    .sponsors-container {
        /*flex-direction: column;*/
        margin-left: 30px;
        margin-right: 30px;
    }

    .sponsor {
        flex: 1;
        padding: 0.5em;/* add some padding ?*/
        text-align: center;
        /*border-bottom: 1px solid #E5E5E5;
        border-right: none;*/
        margin-bottom: 25px;
    }
    .sponsor:last-child {
       /* border: none;  */
    }

    .footer-items-container {
        flex-direction: column;
    }

    .row {
        flex-direction: column;
        align-items: unset;
        margin-left: 30px;
        margin-right: 30px;
    }

    .info {
        /*align-items: center;*/
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 0;
    }

    .bottom-footer {
        margin-left: 30px;
        margin-right: 30px;
    }
        
    .sponsor-cont {
        margin-left: 30px;
        margin-right: 30px;
    }

}