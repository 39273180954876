.nav-container {
    display: flex;
    height: 115px;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    /*background-image: url('../../../public/images/Asset_1.svg');*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.logo {
    margin-left: 100px;
}

.logo img {
    width: 200px;
    cursor: pointer;
}

.links-container {
    display: flex;
    justify-content: space-between;
    margin-right: 100px;
    width: 520px;
}

.links {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Neue Plak Bold';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    cursor: pointer;

    color: #160259;
}

.links a {
    text-decoration: none;
    color: #160259;
}

.link-active a {
    color: #160259;

}

.fantasy-cont {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    margin-left: 10px;
    cursor: pointer;
}

.fantasy-cont-desk {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    /* margin-left: 30px; */
    cursor: pointer;
    color: #160259;
    font-family: 'Neue Plak Bold';
    font-size: 18px;
}



.fantasy-fantasy {
    width: 50px;
    height: 20px;
    margin-top: 10px;
}

.dot-active {
    display: none;
    height: 5px;
    width: 5px;
    background-color: #1EB6FF;
    border-radius: 50%;
    /*display: inline-block;*/
}


.links-container-mobile {
    display: none;
}

.nav-dropdown-links {
    position: absolute;
    width: 100%;
    left: 0;
    margin-top: 555px;
    padding-bottom: 20px;
    color: white;
    font-family: 'Neue Plak SemiBold';
    font-size: 25px;
    display: none;
    flex-direction: column;
    background-image: url('../../../public/images/Asset_1.svg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.nav-dropdown-links a {
    margin-left: 30px;
    text-decoration: none;
    color: white;
}

.nav-dropdown-links p  {
}

.links-container-mobile img {
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
}

@media only screen and (max-width: 1100px) {
    .nav-container {
        height: 100px;
    }
    
    .links-container {
        display: none;
    }

    .links-container-mobile {
        display: flex;
        align-items: center;
        margin-right: 30px;
    }

    .logo {
        margin-left: 30px;
    }
    
    .logo img {
        width: 122px;
    }

    .links-container {
        margin-right: 30px;
    }
}

@font-face {
    font-family: 'Neue Plak Condensed';
    src: url('../../fonts/NeuePlak-CondRegular.woff') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}

@font-face {
    font-family: 'Neue Plak Condensed SemiBold';
    src: url('../../fonts/NeuePlak-CondSemiBold.woff') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}

@font-face {
    font-family: 'Neue Plak Condensed SemiBold-1';
    src: url('../../fonts/NeuePlak-CondSemiBold-1.woff') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}