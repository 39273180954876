#container {
    width: 100%;
}

.background-img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: calc(100vw * 0.817);
    top: 0;
    z-index: -1;
}

.home-content {
    z-index: 100;
    letter-spacing: 0;
    min-height: 900px;
}



@media screen and (max-width: 1100px) { 
    .background-img {
        height: calc(100vw * 1.917);
    }

    .home-content {
        /*height: 640px;*/
        z-index: 100;
    }
}