h1 {
    font-family: 'Neue Plak Bold';
    font-size: 40pt;
    color: #160259;
    margin-left: 100px;
    margin-bottom: 0;
}

.temp-container {
    /*height: 70%;*/
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 100px;
}

.temp-container div {
    display: flex;
    /*align-items: center;*/
    justify-content: center;
    width: 100%;
    height: 100%;
}

@font-face {
    font-family: 'Neue Plak Condensed';
    src: url('../../fonts/NeuePlak-CondRegular.woff') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}

@font-face {
    font-family: 'Neue Plak Condensed SemiBold';
    src: url('../../fonts/NeuePlak-CondSemiBold.woff') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}

@font-face {
    font-family: 'Neue Plak SemiBold';
    src: url('../../fonts/Neue-Plak-SemiBold.otf') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}

@font-face {
    font-family: 'Neue Plak Bold';
    src: url('../../fonts/Neue-Plak-Bold.otf') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}

@media screen and (max-width: 1100px) { 
    h1 {
        font-size: 40pt;
        color: #160259;
        margin-left: 30px;
        margin-bottom: 0;
    }

    .temp-container div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
}