.filter-container {
    display: flex;
    align-items: center;
    background-color: white;
    width: 85%;
    max-width: 1200px;
    margin: auto;
    border: 1px solid #E5E5E5;
    font-family: 'Neue Plak Bold';
    font-size: 10pt;
    margin-top: 25px;
    margin-bottom: 50px;
    border-radius: 5px;
}
.filter-container-mobile {
    display: none;
    align-items: center;
    background-color: white;
    width: 90%;
    margin: auto;
    border: 1px solid #E5E5E5;
    font-family: 'Neue Plak Bold';
    font-size: 10pt;
    margin-top: 40px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.filter-container-mobile p {
    color: #1EB6FF;
    margin: 0;
    margin-bottom: 3px;
}

.filter-container p {
    color: #1EB6FF;
    margin: 0;
    margin-bottom: 3px;
}

select {
    background-color: white;
    border: none;
    font-family: 'Neue Plak Bold';
    color: #160259;
    font-size: 11pt;
}

.deild {
    border-right: 1px solid #E5E5E5;
    padding-top: 15px;
    padding-bottom: 15px;
}

.deild-mobile {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 98%;
}

.deild-mobile p {
    margin-left: 30px;
    margin-right: 30px;
}

.deild p {
    margin-left: 30px;
    margin-right: 30px;
}

.deild select {
    margin-left: 26px;
    margin-right: 30px;
}

.deild-mobile select {
    margin-left: 24px;
    width: 90%;
}

.lið {
    border-right: 1px solid #E5E5E5;
    padding-top: 15px;
    padding-bottom: 15px;
}

.lið p {
    margin-left: 30px;
    margin-right: 30px;
}

.lið select {
    margin-left: 26px;
    margin-right: 30px;
}

.umferð {
    border-right: 1px solid #E5E5E5;
    padding-top: 15px;
    padding-bottom: 15px;
}

.umferð p {
    margin-left: 30px;
    margin-right: 30px;
}

.umferð select {
    margin-left: 26px;
    margin-right: 30px;
}

.rest {
    margin-left: auto;
    margin-right: 30px;
    font-size: 11pt;
}

.rest p {
    cursor: pointer;
}

select:focus {outline:0;}


@media only screen and (max-width: 900px) {
    .filter-container {
        display: none;
    }
    .filter-container-mobile {
        display: flex;
    }
}






@font-face {
    font-family: 'Neue Plak SemiBold';
    src: url('../../fonts/Neue-Plak-SemiBold.otf') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}

@font-face {
    font-family: 'Neue Plak Bold';
    src: url('../../fonts/Neue-Plak-Bold.otf') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}