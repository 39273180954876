.lid-lid-cont {
    width: 85%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 150px;
}

.lid-lid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 23.5%;
    /*height: calc(100vw * 0.150);*/
    height: 270px;
    /*min-height: 160px;
    min-width: 180px;*/
    border-radius: 5px;
    border: 1px solid #E5E5E5;
    background-color: white;
    margin-bottom: 20px;
    cursor: pointer;
}

.lid-lid img {
    width: 50%;
    height: auto;
    margin: 0;
}

.lid-nav-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 38.5px;
    gap: 30px;
    padding-top: 10px;
    background-color: white;
}

.lid-nav-img-size {
    height: 35px;
}

@media only screen and (max-width: 1450px) {
    .lid-lid {
        height: calc(100vw * 0.180);
    }
}

@media only screen and (max-width: 1200px) {
    .lid-nav-container {
        display: none;
    }
}

@media only screen and (max-width: 900px) {
    .lid-lid {
        width: 31%;
        height: calc(100vw * 0.250);
    }

    .lid-lid-cont {
        width: 90%;
    }
}

@media only screen and (max-width: 650px) {
    .lid-lid {
        width: 48%;
        height: calc(100vw * 0.400);
    }
}
