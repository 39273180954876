.stada-cont {
    width: 85%;
    max-width: 1200px;
    margin: auto;
}

.stada-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 1px solid #E5E5E5;
    font-family: 'Neue Plak Bold';
    font-size: 13pt;
    color: #160259;
    margin-bottom: 150px;
    border-radius: 5px;
}

.lid-cont {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.lid-cont p {
    font-family: 'Neue Plak SemiBold';
}

.lid-cont img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.col-names {
    display: flex;
    font-family: 'Neue Plak SemiBold';
    font-size: 11pt;
    color: #160259;
}

.lid-imgText {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 48%;
}

.pos {
    display: flex;
    justify-content: center;
    width: 8%;
}

.played {
    display: flex;
    justify-content: center;
    width: 4%;
}

.skorad-fengid {
    display: flex;
    justify-content: center;
    width: 7%;
}

.sidustu5 {
    display: flex;
    justify-content: center;
    width: 11%;
}


@media only screen and (max-width: 1100px) {
    .hide-mobile {
        display: none;
    }

    p.lid-imgText {
        width: 60%;
    }

    .played {
        width: 8%;
    }

    .sidustu5 {
        width: 18%;
    }

    .stada-container {
        font-size: 10pt;
    }

    .col-names {
        font-size: 10pt;
    }
}


.w {
    width: 8px;
    height: 8px;
    margin-right: 2px;
    background-color: rgb(0, 209, 0);
    border-radius: 5px;
}

.l {
    width: 8px;
    height: 8px;
    margin-right: 2px;
    background-color: red;
    border-radius: 5px;
}

.d {
    width: 8px;
    height: 8px;
    margin-right: 2px;
    background-color: rgb(247, 242, 0);
    border-radius: 5px;
}

.n {
    width: 8px;
    height: 8px;
    margin-right: 2px;
    background-color: lightgray;
    border-radius: 5px;
}

.sidustu5-container {
    width: 11%;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 900px) {
    .pos {
        width: 11%;
    }

    .n {
        width: 8px;
        height: 8px;
        margin-right: 2px;
        background-color: lightgray;
        border-radius: 50%;
    }

    .sidustu5-container {
        width: 14%;
    }

    .stada-cont {
        width: 90%;
    }
}







@font-face {
    font-family: 'Neue Plak SemiBold';
    src: url('../../fonts/Neue-Plak-SemiBold.otf') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}

@font-face {
    font-family: 'Neue Plak Bold';
    src: url('../../fonts/Neue-Plak-Bold.otf') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}