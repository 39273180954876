.games-slider-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 350px;
    background-image: url('../../../public/images/slider-bg.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.games-slider {
    display: flex;
    background-color: white;
    border-radius: 20px;
    height: 180px;
    width: 95%;
    overflow: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.slider-game-container {
    display: flex;
    min-width:250px;
    padding-right: 25px;
}

.slider-game {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-width:250px;
    color: #160259;
    padding-right: 25px;
}

.slider-game-info {
    display: flex;
    padding-left: 25px;
    justify-content: space-between;
}

.slider-game-teams {
    display: flex;
    flex-direction: column;
}

.slider-game-team {
    display: flex;
    align-items: center;
}

.slider-game-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.slider-logo {
    height: 35px;
}

.slider-team-name {
    font-weight: bold;
    padding-left: 10px;
}

.slider-time {
    text-align: center;
    margin-bottom: -12px;
}

.slider-date {
    text-align: right;
    font-size: 13px;
}

.slider-game-separator {
    height: 120px;
    border: solid 1px #FF0F9D;
    align-self: center;
}

.slider-game-tickets {
    display: flex;
    justify-content: center;
}

.slider-tickets-button {
    background-color: #FF0F9D;
    border: none;
    color: white;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 5px;
    margin-left: 25px;
    font-size: 14px;
    margin-top: 5px;
    cursor: pointer;
}

.pill {
    cursor: pointer;
    background-color: white;
    color: #160259;
    font-weight: bold;
    border-radius: 20px;
    padding: 5px;
    width: 80px;
    min-width: 80px;
    text-align: center;
}

.pill-active {
    cursor: pointer;
    background-color: #00F7AA;
    color: #160259;
    font-weight: bold;
    border-radius: 20px;
    padding: 5px;
    width: 80px;
    min-width: 80px;
    text-align: center;
}

@media only screen and (max-width: 900px) {
    .games-slider-container {
        height: 300px;
    }

    .games-slider {
        border-radius: 0;
        width: 100%;
    }

    .slider-game-info {
        padding-left: 20px;
    }
}