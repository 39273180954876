.cont-cont {
    position: absolute;
    top: 0;
    width: 100%;
    background-image: url('../../../public/images/Asset_1.svg');
    /*background-repeat: repeat-y;*/
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 700px;
    z-index: -1;
}

.text-container {
    margin-top: 250px;
    margin-left: 200px;
    margin-right: 200px;
    z-index: 100;
    max-width: 1000px;
}

.text-container h1 {
    margin-left: 0;
    font-family: 'Nueue Plak Bold';
}

.text-container h2 {
    color: white;
    font-size: 25pt;
    font-family: 'Neue Plak Condensed';
    letter-spacing: 2px;
}

.text-container p {
    font-family: 'Neue Plak Condensed SemiBold';
    font-size: 15pt;
    letter-spacing: 0.6px;
    line-height: 1.6;
    color: white;
}

@media screen and (max-width: 1100px) { 
    .text-container {
        margin-top: 200px;
        margin-left: 30px;
        margin-right: 30px;
        /*width: 85%;*/
    }

}

@media screen and (max-width: 800px) { 

    .cont-cont {
        height: 750px;
    }
}

@media screen and (max-width: 650px) { 

    .cont-cont {
        height: 800px;
    }
}

@media screen and (max-width: 600px) { 

    .cont-cont {
        height: 850px;
    }
}

@media screen and (max-width: 500px) { 

    .cont-cont {
        height: 1100px;
    }
}


@font-face {
    font-family: 'Neue Plak Condensed';
    src: url('../../fonts/NeuePlak-CondRegular.woff') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}

@font-face {
    font-family: 'Neue Plak Condensed SemiBold';
    src: url('../../fonts/NeuePlak-CondSemiBold.woff') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}

@font-face {
    font-family: 'Neue Plak Condensed SemiBold-1';
    src: url('../../fonts/NeuePlak-CondSemiBold-1.woff') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}