.fantasy-banner-container {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #160259;
    padding-top: 30px;
    padding-bottom: 30px;
}

.fantasy-big {
    width: 150px;
    margin-right: 5px;
}

.fantasy-fantasy-big {
    width: 100px;
}

.fantasy-cont {
    display: flex;
    flex-direction: column;
}

.fantasy-button {
    cursor: pointer;
    margin-top: 15px;
    width: 255px;
    padding-top: 10px;
    padding-bottom: 14px;
    background-color: transparent;
    color: white;
    font-size: 12pt;
    border: solid 0.1px white;
    border-radius: 5px;
    font-family: 'Neue Plak SemiBold';
    cursor: pointer;
}

@font-face {
    font-family: 'Neue Plak Condensed';
    src: url('../../fonts/NeuePlak-CondRegular.woff') format('woff');
    /*font-weight: 400;*/
    font-style: normal;
}