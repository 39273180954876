.dummy-data {
    display: flex;
    justify-content: space-around;
    margin-bottom: 100px;
}

.single-image {
    width: 100%;
    margin-top: 20px;
}

#media-container {
    width: 100%;
}

.home-content {
    z-index: 100;
}

.img-background {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.head-text {
    height: 700px;
}

.text-2 {
    color: #160259;
    margin-left: 200px;
    margin-right: 200px;
    margin-top: 50px;
    font-family: 'Neue Plak Condensed';
    font-size: 15pt;
    letter-spacing: 0.6px;
    line-height: 1.6;
    max-width: 1000px;
}
.text-2 h2 {
    font-size: 25pt;
    font-family: 'Neue Plak Condensed';
    letter-spacing: 2px;
}

.video-media-h2 {
    font-size: 25pt;
    font-family: 'Neue Plak Condensed';
    letter-spacing: 2px;
    margin-left: 200px;
    margin-top: 100px;
}

.myndefni-cont {
    margin-left: 200px;
    margin-right: 200px;
}

.myndefni-cont h2 {
    font-family: 'Neue Plak Condensed';
    font-size: 25pt;
    letter-spacing: 2px;
    color: #160259;
}

.myndefni {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.double {
    display: flex;
    justify-content: space-between;
}

.single {
    width: 49%;
    margin-top: 30px
}

.merki {
    margin-bottom: 100px;
}

#dl-link {
    margin-left: 0;
    text-decoration: none;
    font-family: 'Neue Plak Condensed';
    font-size: 30px;
    letter-spacing: 2px;
    color: #160259;
    text-decoration: underline;
}

#dl-link:hover {
    color: #4d2db4;
}


@media screen and (max-width: 1100px) { 
    .background-img {
        height: calc(100vw * 1.917);
    }

    .home-content {
        /*height: 640px;*/
        z-index: 100;
    }

    .myndefni-cont {
        margin-left: 30px;
        margin-right: 30px;
    }

    .text-2 {
        margin-left: 30px;
        margin-right: 30px;
    }

    #dl-link {
        margin-left: 0;
        font-size: 20px;
    }

    .video-media-h2 {
        margin-left: 30px;
    }
}

@media screen and (max-width: 700px) { 
    .dummy-data {
        flex-direction: column;
        align-items: center;
    }

    .single-image {
        width: 100%;
    }

    .head-text {
        height: 800px;
    }
}

@media screen and (max-width: 500px) { 
    .head-text {
        height: 1000px;
    }
}